import type * as CSS from 'csstype'

import { grid } from './grid'
import { px } from '../../utils/css-unit'

type LineHeight = CSS.Property.LineHeight<string | number>

type LineHeights = Readonly<{
    'xxx-small': LineHeight
    'xx-small': LineHeight
    'x-small': LineHeight
    small: LineHeight
    medium: LineHeight
    large: LineHeight
    'x-large': LineHeight
    'xx-large': LineHeight
    'xxx-large': LineHeight
}>

export const lineHeights: LineHeights = {
    'xxx-small': px(grid * 2),
    'xx-small': px(grid * 2),
    'x-small': px(grid * 2),
    small: px(grid * 2),
    medium: px(grid * 3),
    large: px(grid * 3),
    'x-large': px(grid * 4),
    'xx-large': px(grid * 6),
    'xxx-large': px(grid * 10),
}
