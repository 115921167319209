import type * as CSS from 'csstype'
import type { TLengthStyledSystem } from 'styled-system'
import { system } from 'styled-system'

import type { SpaceAccessPaths } from '../../theme/tokens/generated/token-access-paths'
import { widthTransformer } from '../prop-transformers'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

export type LayoutProps = {
    width?: ResponsiveValue<CSS.Property.Width<TLengthStyledSystem>>
    height?: ResponsiveValue<CSS.Property.Height<TLengthStyledSystem>>
    minWidth?: ResponsiveValue<CSS.Property.MinWidth<TLengthStyledSystem>>
    minHeight?: ResponsiveValue<CSS.Property.MinHeight<TLengthStyledSystem>>
    maxWidth?: ResponsiveValue<CSS.Property.MaxWidth<TLengthStyledSystem>>
    maxHeight?: ResponsiveValue<CSS.Property.MaxHeight<TLengthStyledSystem>>
    overflow?: ResponsiveValue<CSS.Property.Overflow>
    display?: ResponsiveValue<CSS.Property.Display>
    verticalAlign?: ResponsiveValue<CSS.Property.VerticalAlign<TLengthStyledSystem>>
    flex?: ResponsiveValue<CSS.Property.Flex<TLengthStyledSystem>>
    flexGrow?: ResponsiveValue<CSS.Property.FlexGrow>
    flexShrink?: ResponsiveValue<CSS.Property.FlexShrink>
    flexBasis?: ResponsiveValue<CSS.Property.FlexBasis<TLengthStyledSystem>>
    justifySelf?: ResponsiveValue<CSS.Property.JustifySelf>
    alignSelf?: ResponsiveValue<CSS.Property.AlignSelf>
    order?: ResponsiveValue<CSS.Property.Order>

    gridGap?: ResponsiveValue<CSS.Property.GridGap<TLengthStyledSystem>>
    gridRowGap?: ResponsiveValue<CSS.Property.GridRowGap<TLengthStyledSystem>>
    gridColumnGap?: ResponsiveValue<SpaceAccessPaths>
    gridColumn?: ResponsiveValue<CSS.Property.GridColumn>
    gridRow?: ResponsiveValue<CSS.Property.GridRow>
    gridArea?: ResponsiveValue<CSS.Property.GridArea>
    gridAutoFlow?: ResponsiveValue<CSS.Property.GridAutoFlow>
    gridAutoRows?: ResponsiveValue<CSS.Property.GridAutoRows<TLengthStyledSystem>>
    gridAutoColumns?: ResponsiveValue<CSS.Property.GridAutoColumns<TLengthStyledSystem>>
    gridTemplateRows?: ResponsiveValue<CSS.Property.GridTemplateRows<TLengthStyledSystem>>
    gridTemplateColumns?: ResponsiveValue<CSS.Property.GridTemplateColumns<TLengthStyledSystem>>
    gridTemplateAreas?: ResponsiveValue<CSS.Property.GridTemplateAreas>
}

/** @public used in docs */
export const layoutConfig: Config<keyof LayoutProps> = {
    width: {
        property: 'width',
        scale: 'space',
        transform: widthTransformer,
    },
    height: {
        property: 'height',
        scale: 'space',
    },
    minWidth: {
        property: 'minWidth',
        scale: 'space',
    },
    minHeight: {
        property: 'minHeight',
        scale: 'space',
    },
    maxWidth: {
        property: 'maxWidth',
        scale: 'space',
    },
    maxHeight: {
        property: 'maxHeight',
        scale: 'space',
    },
    overflow: true,
    display: true,
    verticalAlign: true,

    // flexitem
    flex: true,
    flexGrow: true,
    flexShrink: true,
    flexBasis: true,
    justifySelf: true,
    alignSelf: true,
    order: true,

    gridGap: {
        property: 'gridGap',
        scale: 'space',
    },
    gridRowGap: {
        property: 'gridRowGap',
        scale: 'space',
    },
    gridColumnGap: {
        property: 'gridColumnGap',
        scale: 'space',
    },
    gridColumn: true,
    gridRow: true,
    gridArea: true,
    gridAutoFlow: true,
    gridAutoRows: true,
    gridAutoColumns: true,
    gridTemplateRows: true,
    gridTemplateColumns: true,
    gridTemplateAreas: true,
}

export const layout = system(layoutConfig)
