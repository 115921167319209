import type * as CSS from 'csstype'

type Border = CSS.Property.Border<string | number>

export const sideBorders: Readonly<{
    none: Border
    thin: Border
    thick: Border
}> = {
    none: 'none',
    thin: '1px solid',
    thick: '2px solid',
}
