import type * as CSS from 'csstype'

import { px } from '../../utils/css-unit'

type Border = CSS.Property.Border<string | number>

type Borders = Readonly<{
    none: Border
    thin: Border
    thick: Border
}>

export const borders: Borders = {
    none: px(0),
    thin: px(1),
    thick: px(2),
}
