import type * as CSS from 'csstype'

import { px } from '../../utils/css-unit'

type FontSize = CSS.Property.FontSize<string | number>

type FontSizes = Readonly<{
    'xx-small': FontSize
    'x-small': FontSize
    small: FontSize
    medium: FontSize
    large: FontSize
    'x-large': FontSize
    'xx-large': FontSize
}>

export const fontSizes: FontSizes = {
    'xx-small': px(10),
    'x-small': px(12),
    small: px(14),
    medium: px(16),
    large: px(20),
    'x-large': px(24),
    'xx-large': px(40),
}
