const authorizations = '/workspaces/:stackUuid/authorizations/'
const dataQuality = '/data-quality/'
const datastreams = '/datastreams/'
const datastreamOverview = `${datastreams}:datastreamId/` as const
const mapping = '/mapping/'
const target = '/target/'
const workspaces = '/activity/'
const connectorEngine = '/connector-engine/'

export const paths = {
    administration: '/administration/',
    authorizationDetails: `/workspaces/:stackUuid/authorizations/:authorizationId/`,
    authorizationsAdd: '/core/authorization/add/',
    authorizationsList: authorizations,
    connectorEngine,
    connectorEngineConnector: `${connectorEngine}:connectorSlug/`,
    connectorEngineConnectorConfig: `${connectorEngine}:connectorSlug/config/`,
    connectorEngineConnectorWorkflow: `${connectorEngine}:connectorSlug/workflow/:workflow`,
    connectorEngineHelp: `${connectorEngine}help/`,
    dataQuality,
    dataQualityDuplicateIssue: `${dataQuality}duplication/issues/:issueId/`,
    dataQualityDuplication: `${dataQuality}duplication/`,
    dataQualityEditMonitor: `${dataQuality}monitors/:monitorUuid`,
    dataQualityUniversalMonitorRowDuplicates: `${dataQuality}universal-monitors/duplicates/`,
    dataQualityValidity: `${dataQuality}validity/`,
    dataQualityValidityIssue: `${dataQuality}validity/issues/:issueId/`,
    datastreamExtractColumn: `${datastreamOverview}extracts/:extractId/column/`,
    datastreamExtractDuplicateIssue: `${datastreamOverview}extracts/:extractId/issues/row-duplicates/:issueId/`,
    datastreamExtractEnrichment: `${datastreamOverview}extracts/:extractId/enrich/:enrichmentId/`,
    datastreamExtractPreview: `${datastreamOverview}extracts/:extractId/`,
    datastreamExtractsList: `${datastreamOverview}extracts/`,
    datastreamExtractValueIssue: `${datastreamOverview}extracts/:extractId/issues/values/:issueId/`,
    datastreamHistory: `${datastreamOverview}history/`,
    datastreamIssues: `${datastreamOverview}issues/`,
    datastreamMapping: `${datastreamOverview}mapping/`,
    datastreamOverview,
    datastreamRetention: `${datastreamOverview}retention/`,
    datastreams,
    datastreamsAdd: '/core/apidatastream/add/',
    datastreamSettings: `${datastreamOverview}settings/`,
    datastreamsList: `${datastreams}list/`,
    datastreamWorkflow: `${datastreamOverview}workflow/`,
    destinationsAdd: '/target/basetarget/add/',
    enrichmentsImport: '/enrichments/enrichment/import',
    exploreUpsell: '/feature/explore',
    extractionLogsList: '/extraction-logs/list/',
    extractsList: '/extracts/list/',
    home: '/home/',
    login: '/login/',
    mappingTables: `${mapping}mappingtable/`,
    mediaScreenshots: '/imgsrv/mediascreenshot/',
    performanceManager: '/performance-manager/',
    presentUpsell: '/feature/present',
    profile: '/accounts/profile/',
    systemManagedAuthorizationsList: `${authorizations}system-managed-list/`,
    targetColumns: `${target}targetcolumns/`,
    transferList: `${target}list/`,
    usageManager: '/usage-manager/',
    valueTables: `${mapping}valuetable/`,
    workspace: `${workspaces}:stackSlug/`,
    workspaceMappings: '/adverity_workspace/adverityworkspacemappingtable/',
}

export const getPathTo = {
    administration: () => paths.administration,
    authorizationDetails: ({ authorizationId, stackUuid }: { authorizationId: number; stackUuid: string }) =>
        `/workspaces/${stackUuid}/authorizations/${authorizationId}/`,
    authorizationsAdd: () => paths.authorizationsAdd,
    authorizationsList: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/authorizations/`,
    connectorEngine: () => paths.connectorEngine,
    connectorEngineConnector: ({ connectorSlug }: { connectorSlug: string }) => `${connectorEngine}${connectorSlug}/`,
    connectorEngineConnectorConfig: ({ connectorSlug }: { connectorSlug: string }) =>
        `${connectorEngine}${connectorSlug}/config/`,
    connectorEngineConnectorWorkflow: ({ connectorSlug, workflow }: { connectorSlug: string; workflow: string }) =>
        `${connectorEngine}${connectorSlug}/workflow/${workflow}`,
    connectorEngineHelp: () => paths.connectorEngineHelp,
    dataQuality: () => paths.dataQuality,
    dataQualityDuplicateIssue: ({ issueId }: { issueId: number }) =>
        `${paths.dataQuality}duplication/issues/${issueId}/`,
    dataQualityDuplication: () => `${paths.dataQuality}duplication/`,
    dataQualityEditMonitor: ({ monitorUuid }: { monitorUuid: string }) => `${paths.dataQuality}monitors/${monitorUuid}`,
    dataQualityUniversalMonitorRowDuplicates: () => `${paths.dataQuality}universal-monitors/duplicates/`,
    dataQualityValidity: () => `${paths.dataQuality}validity/`,
    dataQualityValidityIssue: ({ issueId }: { issueId: number }) => `${paths.dataQuality}validity/issues/${issueId}/`,
    datastreamExtractColumn: ({ datastreamId, extractId }: { datastreamId: number; extractId: number }) =>
        `/datastreams/${datastreamId}/extracts/${extractId}/column/`,
    datastreamExtractDuplicateIssue: ({
        datastreamId,
        extractId,
        issueId,
    }: {
        datastreamId: number
        extractId: number
        issueId: number
    }) => `/datastreams/${datastreamId}/extracts/${extractId}/issues/row-duplicates/${issueId}/`,
    datastreamExtractEnrichment: ({
        datastreamId,
        extractId,
        enrichmentId,
    }: {
        datastreamId: number
        extractId: number
        enrichmentId: number
    }) => `/datastreams/${datastreamId}/extracts/${extractId}/enrich/${enrichmentId}/`,
    datastreamExtractPreview: ({ datastreamId, extractId }: { datastreamId: number; extractId: number }) =>
        `/datastreams/${datastreamId}/extracts/${extractId}/`,
    datastreamExtractsList: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/extracts/`,
    datastreamExtractValueIssue: ({
        datastreamId,
        extractId,
        issueId,
    }: {
        datastreamId: number
        extractId: number
        issueId: number
    }) => `/datastreams/${datastreamId}/extracts/${extractId}/issues/values/${issueId}/`,
    datastreamHistory: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/history/`,
    datastreamIssues: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/issues/`,
    datastreamMapping: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/mapping/`,
    datastreamOverview: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/`,
    datastreamRetention: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/retention/`,
    datastreamSettings: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/settings/`,
    datastreams: () => paths.datastreams,
    datastreamsWizard: ({ authorizationId, contentId }: { authorizationId?: number; contentId?: number }) => {
        const wizardUrl = paths.datastreamsAdd
        const params = new URLSearchParams()

        if (authorizationId) {
            params.append('auth', authorizationId.toString())
        }

        if (contentId) {
            params.append('ct_id', contentId.toString())
        }

        const stringifiedParams = params.toString()

        return stringifiedParams ? `${wizardUrl}?${stringifiedParams}` : wizardUrl
    },
    datastreamsList: () => paths.datastreamsList,
    destinations: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/destinations/`,
    destinationWizard: (options?: { authorizationId: number }) => {
        const wizardUrl = '/target/basetarget/add/'
        const params = new URLSearchParams()

        if (options?.authorizationId) {
            params.append('auth', options.authorizationId.toString())
        }

        const stringifiedParams = params.toString()

        return stringifiedParams ? `${wizardUrl}?${stringifiedParams}` : wizardUrl
    },
    downloadJobArtifact: (artifactId: number) => `/api/web-app/artifact/${artifactId}/download/`,
    enrichmentsList: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/enrichments/`,
    enrichmentsAdd: ({ stackUuid, datastreamId }: { stackUuid: string; datastreamId?: number }) => {
        const baseUrl = `/workspaces/${stackUuid}/enrichments/add/`
        const params = new URLSearchParams()

        if (datastreamId) {
            params.append('datastreamId', datastreamId.toString())
        }

        const stringifiedParams = params.toString()

        return stringifiedParams ? `${baseUrl}?${stringifiedParams}` : baseUrl
    },
    enrichmentsAddNew: ({ stackUuid, datastreamId }: { stackUuid: string; datastreamId?: number }) => {
        const baseUrl = `/workspaces/${stackUuid}/enrichments/`
        const params = new URLSearchParams()

        params.append('create', 'new')

        if (datastreamId) {
            params.append('datastreamId', datastreamId.toString())
        }

        const stringifiedParams = params.toString()

        return `${baseUrl}?${stringifiedParams}`
    },
    enrichExport: (
        params: Partial<{
            polymorphic_ctype: string
            ordering: string
            workspace: string
            q: string
        }> &
            ({ exclude_entity_ids: string } | { entity_ids: string }),
    ) => {
        const modifiedParams = new URLSearchParams(params).toString()

        return `/api/web-app/enrichments/actions/export/?${modifiedParams}`
    },
    enrichmentDetails: ({
        enrichmentId,
        stackUuid,
        datastreamId,
        extractId,
    }: {
        enrichmentId: number
        stackUuid: string
        datastreamId?: number
        extractId?: number
    }) => {
        const baseUrl = `/workspaces/${stackUuid}/enrichments/details/${enrichmentId}/`
        const params = new URLSearchParams()

        if (datastreamId) {
            params.append('datastreamId', datastreamId.toString())
        }

        if (extractId) {
            params.append('extractId', extractId.toString())
        }

        const stringifiedParams = params.toString()

        return stringifiedParams ? `${baseUrl}?${stringifiedParams}` : baseUrl
    },
    enrichmentsImport: () => paths.enrichmentsImport,
    exploreUpsell: () => paths.exploreUpsell,
    extractionLogsList: '/extraction-logs/list/',
    extractsList: '/extracts/list/',
    help: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/help/`,
    dataShares: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/data-shares/`,
    home: () => paths.home,
    login: ({ next }: { next?: string } = {}) => `${paths.login}${next ? `?next=${next}` : ''}`,
    mappingTables: () => paths.mappingTables,
    mediaScreenshots: () => '/imgsrv/mediascreenshot/',
    onboarding: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/onboarding/`,
    performanceManager: () => paths.performanceManager,
    presentUpsell: () => paths.presentUpsell,
    profile: () => paths.profile,
    schemaMapping: () => '/target/targetcolumns/',
    systemManagedAuthorizationsList: ({ stackUuid }: { stackUuid: string }) =>
        `/workspaces/${stackUuid}/authorizations/system-managed-list/`,
    targetColumns: () => paths.targetColumns,
    transferList: () => paths.transferList,
    usageManager: () => paths.usageManager,
    valueTables: () => paths.valueTables,
    workspace: ({ stackSlug }: { stackSlug: string }) => `${workspaces}${stackSlug}/`,
    workspaceMappings: () => paths.workspaceMappings,
}
