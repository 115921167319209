import { matchRoutes } from 'react-router-dom'

import { paths } from './paths'

const routesMap = Object.values(paths).map((path) => ({ path }))

export const getMixpanelPageKey = () => {
    const matchedPaths = new Set(
        matchRoutes(routesMap, window.location.pathname)?.map((matchedRoute) => matchedRoute.route.path),
    )

    return Object.entries(paths).find(([_, path]) => matchedPaths.has(path))?.[0]
}
