import type * as CSS from 'csstype'
import { system } from 'styled-system'

import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

export type FlexProps = {
    alignItems?: ResponsiveValue<CSS.Property.AlignItems>
    alignContent?: ResponsiveValue<CSS.Property.AlignContent>
    justifyItems?: ResponsiveValue<CSS.Property.JustifyItems>
    justifyContent?: ResponsiveValue<CSS.Property.JustifyContent>
    flexWrap?: ResponsiveValue<CSS.Property.FlexWrap>
    flexDirection?: ResponsiveValue<CSS.Property.FlexDirection>
}

/** @public used in docs */
export const flexConfig: Config<keyof FlexProps> = {
    alignItems: true,
    alignContent: true,
    justifyItems: true,
    justifyContent: true,
    flexWrap: true,
    flexDirection: true,
}

export const flex = system(flexConfig)
