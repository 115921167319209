import type * as CSS from 'csstype'

// Using `BoxShadowProperty` as it seems to be fitting all other shadow types defined in `csstype`
type Shadow = CSS.Property.BoxShadow

type Shadows = Readonly<{
    none: Shadow
    medium: Shadow
}>

export const shadows: Shadows = {
    none: 'none',
    medium: '0px 4px 4px 0px rgba(24, 36, 71, 0.16), 0px 8px 16px 0px rgba(17, 25, 40, 0.16)',
}
