import type { ReactElement, ReactNode } from 'react'
import { useId } from 'react'

import { useField } from '@react-aria/label'

import type { FieldContext } from './context'
import { FieldContextProvider, useFieldContext } from './context'
import { FieldLabel } from './field-label'
import { FieldLayout } from './field-layout'
import { isLabelLessComponent } from './utils'
import type { Orientation, Variant } from '../../types'
import { Box } from '../box/box'
import { HelpText } from '../help-text/help-text'
import type { InfoTipProp } from '../info-tip/utils'
import { ConditionalWrapWithTooltip } from '../tooltip-internal/conditional-wrap-with-tooltip'

type FieldState = { invalid: boolean; error?: { message?: string } }
type VariantFields = {
    variant?: Variant
    /** displayed when the variant is set to `danger` */
    errorMessage?: ReactNode
    /** displayed when the variant is set to `warning` */
    warningMessage?: ReactNode
    /** displayed when the variant is set to `success` */
    successMessage?: ReactNode
}

type FieldStateProps =
    | {
          /**
           * The state of the field, e.g. from react-hook-form
           * Will set the variant and messages accordingly
           */
          state?: FieldState
      }
    | (VariantFields & { state?: never })

export type CommonProps = Omit<FieldContext, 'variant'> & {
    /** form control  */
    children: ReactElement
    /** add a InfoTip button to the end of the label */
    infoTip?: InfoTipProp
    /** add a tooltip to the Field */
    tooltip?: string
    /** Description of the view */
    helpText?: ReactNode
    /**
     * displays the field in full width, if set to false field will occupy `400px` by default
     * @default false
     */
    fullWidth?: boolean
    /**
     * Styles the field either vertically or horizontally
     * @default 'vertical'
     */
    orientation?: Orientation
} & FieldStateProps

type Props = CommonProps & {
    /** label displayed about the form control  */
    label?: string
}

export const FieldInternal = ({
    children,
    label,
    infoTip,
    helpText,
    tooltip,
    orientation = 'vertical',
    ...props
}: Props) => {
    const fieldContext = useFieldContext()
    const { errorMessage, warningMessage, successMessage, variant = fieldContext.variant } = getVariantFields(props)
    const { labelProps, fieldProps, errorMessageProps, descriptionProps } = useField({
        label: useLabel(label),
        labelElementType: 'label',
        description: helpText,
        errorMessage,
    })
    const isVerticallyOriented = orientation === 'vertical'

    const mutableContext: FieldContext = {
        disabled: props.disabled ?? fieldContext.disabled,
        fieldProps,
        fullWidth: props.fullWidth ?? fieldContext.fullWidth,
        readOnly: props.readOnly ?? fieldContext.readOnly,
        required: props.required ?? fieldContext.required,
        variant,
    }

    /**
     * When the component doesn't have a label, the label-related aria-attributes should not be added to the input
     * element, so these attributes need to be cleared.
     */
    if (!label && mutableContext.fieldProps) {
        mutableContext.fieldProps.id = undefined
        mutableContext.fieldProps['aria-label'] = undefined
        mutableContext.fieldProps['aria-labelledby'] = undefined
    }

    const isSelfLabelledComponent = isLabelLessComponent(children)
    /**
     * When the label is not provided by the consumer and the passed component is a label-less
     * component (Checkbox or Switch) we need to add a slight padding to the container element to
     * make it visually look good.
     */
    const shouldApplySpacing = isSelfLabelledComponent && !label

    // The necessary aria-attributes must be added to the input element when the field has an error message.
    if (variant === 'danger' && mutableContext.fieldProps) {
        mutableContext.fieldProps['aria-invalid'] = true

        if (errorMessage) {
            mutableContext.fieldProps['aria-errormessage'] = errorMessageProps.id
        }
    }

    return (
        <FieldContextProvider {...mutableContext}>
            <Box>
                <ConditionalWrapWithTooltip tooltip={tooltip}>
                    <FieldLayout isWithoutLabel={shouldApplySpacing} orientation={orientation} tooltip={tooltip}>
                        {label && (
                            <FieldLabel
                                as={isSelfLabelledComponent ? 'span' : 'label'}
                                infoTip={infoTip}
                                labelProps={labelProps}
                            >
                                {label}
                            </FieldLabel>
                        )}

                        {!!helpText && (
                            <HelpText
                                {...descriptionProps}
                                css={{
                                    gridArea: 'helpText',
                                    marginTop: orientation === 'vertical' && label ? undefined : 'xx-small',
                                }}
                            >
                                {helpText}
                            </HelpText>
                        )}

                        <Box
                            className="field-input-wrapper"
                            alignItems={isVerticallyOriented ? undefined : 'center'}
                            display={isVerticallyOriented ? undefined : 'flex'}
                            flexGrow={1}
                            gridArea="content"
                            // We can't use the `gap` property here because we have empty grid cells in some cases. This applies to all the other margins.
                            marginTop={getMarginTop(orientation, helpText)}
                        >
                            {children}
                        </Box>

                        {(!!warningMessage || !!successMessage || !!errorMessage) && (
                            <HelpText
                                variant={variant}
                                css={{
                                    gridArea: 'errorMessage',
                                    marginTop: 'xx-small',
                                }}
                                {...(variant === 'danger' ? errorMessageProps : {})}
                            >
                                {variant === 'warning' && warningMessage}
                                {variant === 'success' && successMessage}
                                {variant === 'danger' && errorMessage}
                            </HelpText>
                        )}
                    </FieldLayout>
                </ConditionalWrapWithTooltip>
            </Box>
        </FieldContextProvider>
    )
}

function getMarginTop(orientation: CommonProps['orientation'], helpText: CommonProps['helpText']) {
    if (orientation === 'vertical' && helpText) {
        return 'x-small'
    }

    if (orientation === 'vertical' || orientation === 'vertical-inline') {
        return 'xx-small'
    }

    return undefined
}

function useLabel(label: string | undefined) {
    const unlabelledFieldLabel = `unlabelled-field-${useId()}`

    return label ?? unlabelledFieldLabel
}

function getVariantFields(props: FieldStateProps): VariantFields {
    const defaultMessages = {
        errorMessage: undefined,
        warningMessage: undefined,
        successMessage: undefined,
        variant: undefined,
    } as const

    if ('state' in props) {
        return props.state?.invalid && !!props.state.error
            ? ({ ...defaultMessages, errorMessage: props.state.error.message, variant: 'danger' } as const)
            : defaultMessages
    }

    return {
        ...defaultMessages,
        ...props,
    }
}

FieldInternal.displayName = 'FieldInternal'
