import type * as CSS from 'csstype'

import { grid } from './grid'
import { px } from '../../utils/css-unit'

type Space = CSS.Globals | string | number

type SpacingScale = Readonly<{
    none: Space
    'xx-small': Space
    'x-small': Space
    small: Space
    medium: Space
    large: Space
    'x-large': Space
    'xx-large': Space
    'negative-xx-small': Space
    'negative-x-small': Space
    'negative-small': Space
    'negative-medium': Space
    'negative-large': Space
    'negative-x-large': Space
    'negative-xx-large': Space
}>

export const space: SpacingScale = {
    none: px(0),
    'xx-small': px(grid * 0.5),
    'x-small': px(grid),
    small: px(grid * 2),
    medium: px(grid * 3),
    large: px(grid * 4),
    'x-large': px(grid * 5),
    'xx-large': px(grid * 6),
    'negative-xx-small': px(grid * -0.5),
    'negative-x-small': px(grid * -1),
    'negative-small': px(grid * -2),
    'negative-medium': px(grid * -3),
    'negative-large': px(grid * -4),
    'negative-x-large': px(grid * -5),
    'negative-xx-large': px(grid * -6),
}
