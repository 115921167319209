import type * as CSS from 'csstype'

import { px } from '../../utils/css-unit'

type Radius = CSS.Globals | string | number

type Radii = Readonly<{
    none: Radius
    medium: Radius
    large: Radius
    round: Radius
}>

export const radii: Radii = {
    none: px(0),
    medium: px(5),
    large: px(10),
    round: px(9999),
}
