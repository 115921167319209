import type { ComponentProps, ReactNode } from 'react'

import { omit } from 'remeda'

import { DrawerInternal } from './drawer-internal'
import type { Props as DrawerInternalProps } from './drawer-internal'
import { Button } from '../button/button'
import { ButtonSecondary } from '../button/button-secondary'
import { DialogActions } from '../dialog/dialog-actions'

type Props = Omit<DrawerInternalProps, 'footer'> & {
    contain?: boolean
    footer?: {
        additionalActions?: ReactNode
        cancelAction?: (Omit<ComponentProps<typeof Button>, 'children' | 'label'> & { label?: ReactNode }) | null
        applyAction?: (Omit<ComponentProps<typeof Button>, 'children' | 'label'> & { label?: ReactNode }) | null
    }
}

export const Drawer = ({ children, footer, onClose, ...props }: Props) => (
    <DrawerInternal
        onClose={onClose}
        {...props}
        footer={
            footer ? (
                <DialogActions additionalActions={footer.additionalActions}>
                    {footer.cancelAction && (
                        <ButtonSecondary onClick={onClose} {...omit(footer.cancelAction, ['label'])}>
                            {footer.cancelAction.label ?? 'Cancel'}
                        </ButtonSecondary>
                    )}
                    {footer.applyAction && (
                        <Button type="submit" {...omit(footer.applyAction, ['label'])}>
                            {footer.applyAction.label ?? 'Apply'}
                        </Button>
                    )}
                </DialogActions>
            ) : undefined
        }
    >
        {children}
    </DrawerInternal>
)

Drawer.displayName = 'Drawer'
