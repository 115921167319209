import type * as CSS from 'csstype'
import { system } from 'styled-system'

import type { ColorsAccessPaths } from '../../theme/tokens/generated/token-access-paths'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

export type ColorProps = {
    color?: ColorsAccessPaths | CSS.Globals
    backgroundColor?: ColorsAccessPaths | CSS.Globals
    opacity?: ResponsiveValue<CSS.Property.Opacity>
}

/** @public used in docs */
export const colorConfig: Config<keyof ColorProps> = {
    color: {
        property: 'color',
        scale: 'colors',
    },
    backgroundColor: {
        property: 'backgroundColor',
        scale: 'colors',
    },
    opacity: true,
}

export const color = system(colorConfig)
