import type * as CSS from 'csstype'

type Timing = CSS.Property.AnimationTimingFunction

type TimingFunction = Readonly<{
    linear: Timing
    'ease-in': Timing
    'ease-out': Timing
    'ease-in-out': Timing
}>

export const timingFunction: TimingFunction = {
    linear: 'linear',
    'ease-in': 'ease-in',
    'ease-out': 'ease-out',
    'ease-in-out': 'cubic-bezier(0.45,0.05,0.55,0.95)',
}
