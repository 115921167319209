const tokens: readonly [string, string, string] = ['768px', '1280px', '1440px']

const aliases = {
    small: tokens[0],
    medium: tokens[1],
    large: tokens[2],
} as const

// eslint-disable-next-line functional/immutable-data
export const breakpoints = Object.assign(tokens, aliases)
