import type { TLengthStyledSystem } from 'styled-system'

export const px = (value: TLengthStyledSystem): string =>
    Number.isNaN(Number(value)) ? (value as string) : `${value}px`

export const addCSSUnit = (value: TLengthStyledSystem, scale: Array<TLengthStyledSystem> = []) =>
    px(scale[value as number] ?? value)

export const removePxUnit = (value: string) => Number(value.slice(0, -2))
export const removeTimeUnit = (value: string) => Number(value.slice(1, -1))
