import type { ComponentProps, FormEvent, ReactNode } from 'react'

import { omit } from 'remeda'

import { Button } from '../button/button'
import { ButtonSecondary } from '../button/button-secondary'
import { Dialog } from '../dialog/dialog'
import { DialogActions } from '../dialog/dialog-actions'
import { DialogBackdrop } from '../dialog/dialog-backdrop'
import { DialogBody } from '../dialog/dialog-body'
import { DialogContainer } from '../dialog/dialog-container'
import { DialogFooter } from '../dialog/dialog-footer'
import { DialogHeader } from '../dialog/dialog-header'
import { DialogTitle } from '../dialog/dialog-title'
import { Flex } from '../flex/flex'
import { Separator } from '../separator/separator'

const sizeMapping = {
    small: 480,
    medium: 600,
    large: 920,
    full: '100%',
}

export type ModalDialogProps = {
    title: string
    subHeader?: ReactNode
    size?: 'small' | 'medium' | 'large' | 'full'
    additionalActions?: ReactNode
    cancelAction?: (Omit<ComponentProps<typeof Button>, 'children' | 'label'> & { label?: ReactNode }) | null
    applyAction?: (Omit<ComponentProps<typeof Button>, 'children' | 'label'> & { label?: ReactNode }) | null
    children: ReactNode
    onClose: () => void
    disablePortal?: boolean
    disableFocusTrap?: boolean
    // TODO: Fix signature when https://github.com/pmedianetwork/design-system/issues/1528 is finished.
    // The HTMLElement is a wrong generic type, it should be HTMLFormElement.
    // For further information read this thread: https://github.com/pmedianetwork/design-system/pull/2413#discussion_r591355275
    onSubmit?: (event: FormEvent<HTMLElement>, data: FormData) => void
    isOutsideDismissable?: boolean
} & Pick<ComponentProps<typeof Dialog>, 'role'>

export const ModalDialog = ({
    title,
    subHeader,
    size = 'medium',
    additionalActions,
    cancelAction = { label: 'Cancel' },
    applyAction = { label: 'Apply' },
    disablePortal,
    disableFocusTrap,
    onClose,
    children,
    onSubmit,
    role,
}: ModalDialogProps) => (
    <DialogContainer disablePortal={disablePortal}>
        <DialogBackdrop>
            <Flex height="100%" justifyContent="center" alignItems="center" paddingY="x-small" data-backdrop>
                <Dialog
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '90vh',
                    }}
                    preventScroll
                    as="form"
                    autoFocus
                    contain={!disableFocusTrap}
                    position="relative"
                    onClose={onClose}
                    marginY="auto"
                    marginX={['x-small', 'small', 'large']}
                    width={['auto', sizeMapping[size]]}
                    role={role}
                    isOutsideDismissable={false}
                    onSubmit={(event) => {
                        event.preventDefault()
                        onSubmit?.(event, new FormData(event.target as HTMLFormElement))
                    }}
                    onKeyDown={(event) => {
                        if (event.code === 'Escape') {
                            event.stopPropagation()
                        }
                    }}
                >
                    <DialogHeader onClose={onClose}>
                        <DialogTitle>{title}</DialogTitle>
                    </DialogHeader>
                    <Separator />
                    {subHeader}
                    <DialogBody scrollableContent>{children}</DialogBody>
                    <Separator />
                    {(!!additionalActions || !!cancelAction || !!applyAction) && (
                        <DialogFooter>
                            <DialogActions additionalActions={additionalActions}>
                                {cancelAction && (
                                    <ButtonSecondary onClick={onClose} {...omit(cancelAction, ['label'])}>
                                        {cancelAction.label ?? 'Cancel'}
                                    </ButtonSecondary>
                                )}
                                {applyAction && (
                                    <Button type="submit" {...omit(applyAction, ['label'])}>
                                        {applyAction.label ?? 'Apply'}
                                    </Button>
                                )}
                            </DialogActions>
                        </DialogFooter>
                    )}
                </Dialog>
            </Flex>
        </DialogBackdrop>
    </DialogContainer>
)

ModalDialog.displayName = 'ModalDialog'
