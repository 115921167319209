import type * as CSS from 'csstype'

type ZIndex = CSS.Property.ZIndex

export const zIndices: readonly [ZIndex, ZIndex, ZIndex, ZIndex, ZIndex, ZIndex, ZIndex, ZIndex, ZIndex, ZIndex] = [
    0,
    10, // Group item hover
    20, // Group item focus
    30, // sticky
    40, // fixed
    50, // dropdown
    60, // modal-backdrop
    70, // modal
    80, // popover
    90, // tooltip
]
