import type * as CSS from 'csstype'

type FontWeight = CSS.Property.FontWeight

type FontWeights = Readonly<{
    regular: FontWeight
    semiBold: FontWeight
    bold: FontWeight
}>

export const fontWeights: FontWeights = {
    regular: 400,
    semiBold: 600,
    bold: 700,
}
