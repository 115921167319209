import type * as CSS from 'csstype'

import type { ColorsAccessPaths } from './generated/token-access-paths'
import type { ResponsiveValue } from '../../system/styled-system-re-exported-types'
import { setAlpha } from '../../utils/color'

const text = '#111928'

type Color = CSS.Property.Color

type Misc = {
    shadow: Color
    // CSS type safe
    transparent: 'transparent'
    current: 'currentColor'
}

export type ColorTokens = {
    // Text
    text: Color
    textSecondary: Color
    // Background
    background: Color
    backgroundSecondary: Color
    // Brand
    brand: Color
    brandHighlighted: Color
    brandActive: Color
    brandText: Color
    brandSecondary: Color
    brandSecondaryHighlighted: Color
    brandSecondaryActive: Color
    brandAccent: Color // Currently only used in Sidebar

    // Text
    textMuted: Color
    textInverted: Color
    textInvertedSecondary: Color

    // Background
    backgroundInverted: Color
    backgroundInvertedHighlighted: Color
    backgroundEmphasis: Color
    backgroundContent: Color

    // Border
    border: Color
    borderInverted: Color

    // Variants
    success: Color
    successHighlighted: Color
    successActive: Color
    successText: Color
    successSecondary: Color
    successSecondaryHighlighted: Color
    successSecondaryActive: Color

    warning: Color
    warningHighlighted: Color
    warningActive: Color
    warningText: Color
    warningSecondary: Color
    warningSecondaryHighlighted: Color
    warningSecondaryActive: Color

    danger: Color
    dangerHighlighted: Color
    dangerActive: Color
    dangerText: Color
    dangerSecondary: Color
    dangerSecondaryHighlighted: Color
    dangerSecondaryActive: Color

    metric: Color
    metricHighlighted: Color
    metricActive: Color
    metricText: Color
    metricSecondary: Color
    metricSecondaryHighlighted: Color
    metricSecondaryActive: Color

    dimension: Color
    dimensionHighlighted: Color
    dimensionActive: Color
    dimensionText: Color
    dimensionSecondary: Color
    dimensionSecondaryHighlighted: Color
    dimensionSecondaryActive: Color
}

export type Colors = Misc & ColorTokens

export type ColorToken = keyof Misc | keyof ColorTokens

export const colors: Colors = {
    // Brand
    brand: '#1839A0',
    brandHighlighted: '#2950E3',
    brandActive: '#4071F7',
    brandSecondary: '#F4F8FF',
    brandSecondaryHighlighted: '#E5EEFF',
    brandSecondaryActive: '#D1E1FF',
    brandText: '#182447',
    brandAccent: '#00FFC5',

    // Variants
    success: '#397546',
    successHighlighted: '#4D9B5F',
    successActive: '#64BE79',
    successSecondary: '#E8FBED',
    successSecondaryHighlighted: '#B7ECC4',
    successSecondaryActive: '#8DD7A3',
    successText: '#28513B',

    // Warning
    warning: '#876E2F',
    warningHighlighted: '#C4A650',
    warningActive: '#EDCC74',
    warningSecondary: '#FFF8E7',
    warningSecondaryHighlighted: '#FFF3CD',
    warningSecondaryActive: '#F6E3A7',
    warningText: '#59491D',

    danger: '#7D2521',
    dangerHighlighted: '#A83029',
    dangerActive: '#C94039',
    dangerSecondary: '#F8E8E7',
    dangerSecondaryHighlighted: '#E9ADAB',
    dangerSecondaryActive: '#D36C68',
    dangerText: '#591E1B',

    metric: '#0A857D',
    metricHighlighted: '#06B1A6',
    metricActive: '#07CFC2',
    metricSecondary: '#D3FDF3',
    metricSecondaryHighlighted: '#B5FCEA',
    metricSecondaryActive: '#93FBE7',
    metricText: '#065652',

    dimension: '#0078BD',
    dimensionHighlighted: '#009CF5',
    dimensionActive: '#4FBFFF',
    dimensionSecondary: '#E5F6FF',
    dimensionSecondaryHighlighted: '#C7EBFF',
    dimensionSecondaryActive: '#A9DFFE',
    dimensionText: '#054D76',

    // Text
    text,
    textSecondary: '#6B7280',
    textMuted: '#B2BAC2',
    textInverted: '#F8F9F9',
    textInvertedSecondary: '#98AADD',

    // Background
    background: '#FFFFFF',
    backgroundSecondary: '#F8F9F9',
    backgroundInverted: '#0B1947',
    backgroundInvertedHighlighted: '#FFFFFF66',
    backgroundEmphasis: '#DDE0E4',
    backgroundContent: '#EDEFF1',

    // Border
    border: '#DDE0E4',
    borderInverted: '#FFFFFF40',

    // Shadow
    shadow: setAlpha(text, 0.6).toHex(),
    // CSS type safe
    transparent: 'transparent',
    current: 'currentColor',
}

export const isColorToken = (color?: ResponsiveValue<ColorsAccessPaths | CSS.Globals>): color is ColorToken =>
    !!color && typeof color === 'string' && Object.keys(colors).includes(color)
