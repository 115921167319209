import type { ComponentType, ErrorInfo, ReactNode } from 'react'
import { useSyncExternalStore } from 'react'

import * as Sentry from '@sentry/react'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import type { ErrorBoundaryPropsWithComponent, FallbackProps } from 'react-error-boundary'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { DefaultFallback, ModalDialogFallback } from './fallback'

type Props = Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent' | 'reset'> & {
    children?: ReactNode
    FallbackComponent?: ComponentType<FallbackProps>
}

const popStateSubscribe = (onStoreChange: () => void) => {
    window.addEventListener('popstate', onStoreChange)

    return () => window.removeEventListener('popstate', onStoreChange)
}

export const ErrorBoundary = ({ children, onError, FallbackComponent = DefaultFallback, ...props }: Props) => {
    const { reset } = useQueryErrorResetBoundary()
    const pathname = useSyncExternalStore(popStateSubscribe, () => window.location.pathname)

    const handleError = (error: Error, info: ErrorInfo) => {
        try {
            if (!import.meta.env.DEV) {
                Sentry.captureException(error, {
                    captureContext: {
                        tags: { errorBoundary: true },
                        contexts: { react: { componentStack: info.componentStack } },
                    },
                })
            }

            onError?.(error, info)
        } catch {
            // eslint-disable-next-line no-console
            console.error('Unable to handle onError', error)
        }
    }

    return (
        <ReactErrorBoundary
            {...props}
            onError={handleError}
            onReset={reset}
            FallbackComponent={FallbackComponent}
            onResetKeysChange={reset}
            resetKeys={[pathname]}
        >
            {children}
        </ReactErrorBoundary>
    )
}

export const ModalDialogErrorBoundary = ({ onClose, children }: { onClose: () => void; children: ReactNode }) => (
    <ErrorBoundary FallbackComponent={(fallbackProps) => <ModalDialogFallback {...fallbackProps} onClose={onClose} />}>
        {children}
    </ErrorBoundary>
)
