import { cloneElement, forwardRef, useContext } from 'react'

import { useMergeRefs } from '@floating-ui/react'

import { PopoverContext } from './popover'
import type { PopoverTriggerProps } from './types'

export const PopoverTrigger = forwardRef<HTMLElement, PopoverTriggerProps>(({ children, disabled }, propsRef) => {
    const popoverContext = useContext(PopoverContext)

    if (!popoverContext) {
        throw new Error('Error, state not initialised. Did you use PopoverContent outside the Popover component?')
    }

    const { refs, getReferenceProps, isOpen, setIsOpen } = popoverContext

    const mergedRef = useMergeRefs([refs.setReference, propsRef])

    if (typeof children === 'function') {
        return children({
            ...getReferenceProps({ ref: mergedRef }),

            isOpen,
            setIsOpen,
            disabled,
            ...(disabled && { onClick: () => {} }),
        })
    }

    return cloneElement(children, {
        ...getReferenceProps({ ref: mergedRef, ...children.props }),

        ...(disabled && { onClick: () => {} }),
    })
})

PopoverTrigger.displayName = 'PopoverTrigger'
