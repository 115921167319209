import type { FontSizesAccessPaths, SpaceAccessPaths } from '../theme/tokens/generated/token-access-paths'
import type { Size } from '../types'

export const padding: { [K in Size]: SpaceAccessPaths } = {
    'x-small': 'x-small',
    small: 'x-small',
    medium: 'small',
    large: 'small',
    'x-large': 'medium',
}

export const height: { [K in Size]: SpaceAccessPaths } = {
    'x-small': 'small',
    small: 'medium',
    medium: 'large',
    large: 'x-large',
    'x-large': 'xx-large',
}

export const fontSize: { [K in Size]: FontSizesAccessPaths } = {
    'x-small': 'xx-small',
    small: 'x-small',
    medium: 'small',
    large: 'medium',
    'x-large': 'large',
}

export const iconPadding: { [K in Size]: SpaceAccessPaths } = {
    'x-small': 'xx-small',
    small: 'xx-small',
    medium: 'xx-small',
    large: 'x-small',
    'x-large': 'x-small',
}
