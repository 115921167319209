import type * as CSS from 'csstype'

type LetterSpacing = CSS.Property.LetterSpacing<string | number>

type LetterSpacings = Readonly<{
    normal: LetterSpacing
    caps: LetterSpacing
    loose: LetterSpacing
}>

export const letterSpacings: LetterSpacings = {
    normal: 'normal',
    caps: '.025em',
    loose: '.02em',
}
