import type { AnchorHTMLAttributes, ComponentProps, MouseEventHandler, ReactNode } from 'react'

import { Link } from '@adverity/design-system'
import {
    useNavigate as reactRouterUseNavigate,
    useHref,
    useLinkClickHandler,
    useLocation,
    useSearchParams,
} from 'react-router-dom'

import { getCompatibleTo } from './get-compatible-to'
import { getMixpanelPageKey } from './get-page-key'
import { getSearchObject } from './get-search-object'
import { useCurrentStackUuid } from './page'

/**
 * !Important
 * These functions dynamically replaced according
 * to the atrium/legacy context
 *
 * KEEP it in sync with
 * libs/atrium-router/src/navigation/shared-router.tsx
 */

/**
 * Access router's representation pathname
 * @returns string
 */
export const usePathname = () => {
    const location = useLocation()

    return location.pathname
}

/**
 * Tanstack router specific
 * noop in React Router context
 * @returns
 */
// eslint-disable-next-line @eslint-react/hooks-extra/no-redundant-custom-hook
export const useRouter = () => ({
    invalidate: () => new Promise(() => {}),
})

/**
 * Access workspace id
 * Legacy context access
 * Atrium pathParam access
 * @returns UUID
 */
export const useWorkspaceId = () => useCurrentStackUuid()

/**
 * Access PageKey for Mixpanel
 * @returns
 */
// eslint-disable-next-line @eslint-react/hooks-extra/no-redundant-custom-hook
export const useGetMixpanelPageKey = () => getMixpanelPageKey()

type Props = {
    onClick?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
    children: ReactNode
} & Omit<ComponentProps<typeof Link>, 'href'> &
    Omit<Parameters<typeof getCompatibleTo>[0], 'searchParams' | 'pathname'>

export const RouterLink = ({ onClick, to, from, params, search, ...rest }: Props) => {
    const pathname = usePathname()
    const [searchParams] = useSearchParams()
    const transformedTo = getCompatibleTo({ to, params, from, pathname, search, searchParams })

    const href = useHref(transformedTo)
    const internalOnClick = useLinkClickHandler(transformedTo, { replace: false })

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (onClick) {
            onClick(event)
        }

        if (!event.defaultPrevented) {
            internalOnClick(event)
        }
    }

    return <Link {...rest} href={href} onClick={handleClick} />
}

export const useNavigate = () => {
    const navigate = reactRouterUseNavigate()
    const [searchParams] = useSearchParams()
    const pathname = usePathname()

    return ({
        to,
        search,
        params,
        from,
    }: {
        params?: Record<string, unknown>
        to: string
        from?: string
        search: boolean | Record<string, string | number | Array<string | number>>
    }) => navigate(getCompatibleTo({ to, params, from, pathname, search, searchParams }))
}

/**
 * Access search params as Object
 * @returns
 */
export const useSearch = () => {
    const [searchParams] = useSearchParams()

    return getSearchObject(searchParams)
}
