import type * as CSS from 'csstype'
import type { TLengthStyledSystem } from 'styled-system'
import { system } from 'styled-system'

import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

export type PositionProps = {
    zIndex?: ResponsiveValue<CSS.Property.ZIndex>
    top?: ResponsiveValue<CSS.Property.Top<TLengthStyledSystem>>
    right?: ResponsiveValue<CSS.Property.Right<TLengthStyledSystem>>
    bottom?: ResponsiveValue<CSS.Property.Bottom<TLengthStyledSystem>>
    left?: ResponsiveValue<CSS.Property.Left<TLengthStyledSystem>>
    position?: ResponsiveValue<CSS.Property.Position>
}

/** @public used in docs */
export const positionConfig: Config<keyof PositionProps> = {
    position: true,
    zIndex: {
        property: 'zIndex',
        scale: 'zIndices',
    },
    top: {
        property: 'top',
        scale: 'space',
    },
    right: {
        property: 'right',
        scale: 'space',
    },
    bottom: {
        property: 'bottom',
        scale: 'space',
    },
    left: {
        property: 'left',
        scale: 'space',
    },
}

export const position = system(positionConfig)
