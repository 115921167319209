type Duration = Readonly<{
    fast: string
    normal: string
    slow: string
    slowest: string
}>

export const duration: Duration = {
    fast: '.225s',
    normal: '.3s',
    slow: '.45s',
    slowest: '1s',
}
