import type { TLengthStyledSystem } from 'styled-system'
import { system } from 'styled-system'

import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

type PropsWithMinColumnWidth = {
    /**
     * Sets minimum width of each column. It splits horizontal space between maximum number of columns, that is possible for provided minimum column width.
     * When remaining space is not enough to fit another column, it expands  each column width, so that they take up any available space.
     * Cannot be used in conjunction with 'columnsCount' prop. They use the same CSS property - gridTemplateColumns.
     */
    minColumnWidth?: ResponsiveValue<TLengthStyledSystem>
    columnsCount?: never
}

type PropsWithColumns = {
    /**
     * Splits horizontal space between provided number of columns. Every column gets equal width.
     * Cannot be used in conjunction with 'minColumnWidth' prop. They use the same CSS property - gridTemplateColumns.
     */
    columnsCount?: ResponsiveValue<number>
    minColumnWidth?: never
}

export type GridCustomProps = PropsWithMinColumnWidth | PropsWithColumns

const getWidth = (value: TLengthStyledSystem) => (Number.isFinite(Number(value)) ? `${value}px` : value)

/** @public used in docs */
const gridConfig: Config<keyof GridCustomProps> = {
    columnsCount: {
        property: 'gridTemplateColumns',
        transform: (value) => `repeat(${value}, 1fr)`,
    },
    minColumnWidth: {
        property: 'gridTemplateColumns',
        transform: (value) => `repeat(auto-fit, minmax(${getWidth(value)}, 1fr))`,
    },
}

export const gridCustom = system(gridConfig)
