import type * as CSS from 'csstype'
import { system } from 'styled-system'

import type {
    BordersAccessPaths,
    ColorsAccessPaths,
    RadiiAccessPaths,
    ShadowsAccessPaths,
} from '../../theme/tokens/generated/token-access-paths'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

export type BorderProps = {
    borderWidth?: ResponsiveValue<BordersAccessPaths>
    borderTopWidth?: ResponsiveValue<BordersAccessPaths>
    borderRightWidth?: ResponsiveValue<BordersAccessPaths>
    borderBottomWidth?: ResponsiveValue<BordersAccessPaths>
    borderLeftWidth?: ResponsiveValue<BordersAccessPaths>
    borderRadius?: ResponsiveValue<RadiiAccessPaths>
    borderColor?: ResponsiveValue<ColorsAccessPaths>
    boxShadow?: ResponsiveValue<ShadowsAccessPaths>
    borderStyle?: ResponsiveValue<CSS.Property.BorderStyle>
}

/** @public used in docs */
export const bordersConfig: Config<keyof BorderProps> = {
    borderWidth: {
        property: 'borderWidth',
        scale: 'borders',
    },
    borderTopWidth: {
        property: 'borderTopWidth',
        scale: 'borders',
    },
    borderRightWidth: {
        property: 'borderRightWidth',
        scale: 'borders',
    },
    borderBottomWidth: {
        property: 'borderBottomWidth',
        scale: 'borders',
    },
    borderLeftWidth: {
        property: 'borderLeftWidth',
        scale: 'borders',
    },
    borderColor: {
        property: 'borderColor',
        scale: 'colors',
    },
    borderStyle: true,
    borderRadius: {
        property: 'borderRadius',
        scale: 'radii',
    },
    boxShadow: {
        property: 'boxShadow',
        scale: 'shadows',
    },
}

export const borders = system(bordersConfig)
