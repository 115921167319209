import { system } from 'styled-system'

import type { SpaceAccessPaths, SpaceAccessPathsWithNegatives } from '../../theme/tokens/generated/token-access-paths'
import { marginTransformer } from '../prop-transformers'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

type CalcExpression = `calc(${string})`

export type SpaceProps = {
    margin?: ResponsiveValue<SpaceAccessPathsWithNegatives | 'auto' | CalcExpression>
    marginTop?: ResponsiveValue<SpaceAccessPathsWithNegatives | 'auto' | CalcExpression>
    marginRight?: ResponsiveValue<SpaceAccessPathsWithNegatives | 'auto' | CalcExpression>
    marginBottom?: ResponsiveValue<SpaceAccessPathsWithNegatives | 'auto' | CalcExpression>
    marginLeft?: ResponsiveValue<SpaceAccessPathsWithNegatives | 'auto' | CalcExpression>
    marginX?: ResponsiveValue<SpaceAccessPathsWithNegatives | 'auto' | CalcExpression>
    marginY?: ResponsiveValue<SpaceAccessPathsWithNegatives | 'auto' | CalcExpression>

    padding?: ResponsiveValue<SpaceAccessPaths | CalcExpression>
    paddingTop?: ResponsiveValue<SpaceAccessPaths | CalcExpression>
    paddingRight?: ResponsiveValue<SpaceAccessPaths | CalcExpression>
    paddingBottom?: ResponsiveValue<SpaceAccessPaths | CalcExpression>
    paddingLeft?: ResponsiveValue<SpaceAccessPaths | CalcExpression>
    paddingX?: ResponsiveValue<SpaceAccessPaths | CalcExpression>
    paddingY?: ResponsiveValue<SpaceAccessPaths | CalcExpression>
}

/** @public used in docs */
export const spaceConfig: Config<keyof SpaceProps> = {
    margin: {
        property: 'margin',
        scale: 'space',
        transform: marginTransformer,
    },
    marginTop: {
        property: 'marginTop',
        scale: 'space',
        transform: marginTransformer,
    },
    marginRight: {
        property: 'marginRight',
        scale: 'space',
        transform: marginTransformer,
    },
    marginBottom: {
        property: 'marginBottom',
        scale: 'space',
        transform: marginTransformer,
    },
    marginLeft: {
        property: 'marginLeft',
        scale: 'space',
        transform: marginTransformer,
    },
    marginX: {
        properties: ['marginLeft', 'marginRight'],
        scale: 'space',
        transform: marginTransformer,
    },
    marginY: {
        properties: ['marginTop', 'marginBottom'],
        scale: 'space',
        transform: marginTransformer,
    },
    padding: {
        property: 'padding',
        scale: 'space',
    },
    paddingTop: {
        property: 'paddingTop',
        scale: 'space',
    },
    paddingRight: {
        property: 'paddingRight',
        scale: 'space',
    },
    paddingBottom: {
        property: 'paddingBottom',
        scale: 'space',
    },
    paddingLeft: {
        property: 'paddingLeft',
        scale: 'space',
    },
    paddingX: {
        properties: ['paddingLeft', 'paddingRight'],
        scale: 'space',
    },
    paddingY: {
        properties: ['paddingTop', 'paddingBottom'],
        scale: 'space',
    },
}

export const space = system(spaceConfig)
